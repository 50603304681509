import createBrowserHistory from "history/createBrowserHistory"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Route, Router, Switch } from "react-router-dom"
import { bindActionCreators } from "redux"

import { ThemeProvider } from "styled-components"

import * as DashboardActions from "../actions/dashboard"
import * as TagsActions from "../actions/tag"
import * as FluidityActions from "../actions/fluidity"
import * as Pages from "../pages/"

import { SignatureTheme } from "../themes/"

const history = createBrowserHistory()

const ROUTES = [
  { path: "/", exact: true, component: Pages.Loading },
  { path: "/site", exact: true, component: Pages.Site },
  { path: "/restaurant", exact: true, component: Pages.Restaurant },
  { path: "/foodgroup", exact: true, component: Pages.FoodGroup },
  { path: "/edito", exact: true, component: Pages.EditoItem },
  { path: "/fluidity", exact: true, component: Pages.Fluidity },
  { path: "/signature/restaurant", exact: true, component: Pages.SignatureRestaurant },
  { path: "/signature/foodgroup", exact: true, component: Pages.SignatureFoodGroup },
  { path: "/signature/welcome", exact: true, component: Pages.SignatureFoodGroup },
  { path: "/signature/print", exact: true, component: Pages.SignaturePrint },
  { path: "*", exact: false, component: Pages.NoMatch }
]

class DisplayContainer extends Component {
  render() {
    return (
      <Router history={history}>
        <ThemeProvider theme={SignatureTheme}>
          <Switch>
            {ROUTES.map(({ path, exact, component: Comp }) => {
              return (
                <Route
                  path={path}
                  exact={exact}
                  key={path}
                  render={props => <Comp {...this.props} {...props} />}
                />
              )
            })}
          </Switch>
        </ThemeProvider>
      </Router>
    )
  }
}

DisplayContainer.propTypes = {
  dispatch: PropTypes.func,
  dashboardActions: PropTypes.object.isRequired,
  tagsActions: PropTypes.object.isRequired,
  fluidityActions: PropTypes.object.isRequired
}

export function mapStateToProps(state) {
  const { dashboard, fluidity } = state
  return Object.assign({}, { dashboard, fluidity })
}

export function mapDispatchToProps(dispatch) {
  return {
    dashboardActions: bindActionCreators(DashboardActions, dispatch),
    tagsActions: bindActionCreators(TagsActions, dispatch),
    fluidityActions: bindActionCreators(FluidityActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayContainer)
