import { combineReducers } from "redux"

import dashboard from "./dashboard"
import tags from "./tags"
import fluidity from "./fluidity"

const displayReducer = combineReducers({
  dashboard,
  tags,
  fluidity
})

export default displayReducer
