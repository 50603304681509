import React from "react"
import loadable from "@loadable/component"

const ErrorDisplay = ({ error }) => {
  console.error(error)
  return <div>Unexpected error</div>
}

export const Loading = loadable(() => import("./Loading.js"), {
  ErrorComponent: ErrorDisplay
})

export const NoMatch = loadable(() => import("./NoMatch.js"), {
  ErrorComponent: ErrorDisplay
})

export const FoodGroup = loadable(() => import("./FoodGroup.js"), {
  ErrorComponent: ErrorDisplay
})

export const EditoItem = loadable(() => import("./EditoItem.js"), {
  ErrorComponent: ErrorDisplay
})

export const Fluidity = loadable(() => import("./Fluidity.js"), {
  ErrorComponent: ErrorDisplay
})

export const Restaurant = loadable(() => import("./Restaurant.js"), {
  ErrorComponent: ErrorDisplay
})

export const Site = loadable(() => import("./Site.js"), {
  ErrorComponent: ErrorDisplay
})

export const SignatureRestaurant = loadable(
  () => import("./SignatureRestaurant.js"),
  {
    ErrorComponent: ErrorDisplay
  }
)
export const SignatureFoodGroup = loadable(
  () => import("./SignatureFoodGroup.js"),
  {
    ErrorComponent: ErrorDisplay
  }
)

export const SignaturePrint = loadable(
  () => import("./SignaturePrint.js"),
  {
    ErrorComponent: ErrorDisplay
  }
)

// export const SignatureSite = loadable(() => import("./SignatureSite.js"))
