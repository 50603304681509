import { callApi } from '../middleware/api'
import i18n from "../middleware/i18n"

export const DASHBOARD_REQUEST = 'DASHBOARD_REQUEST'
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS'
export const DASHBOARD_FAILURE = 'DASHBOARD_FAILURE'
export const LANGUAGE_SUCCESS = 'LANGUAGE_SUCCESS'

var dashboard = {}

var getUrl = window.location;

export var apiLink = getUrl .protocol + "//" + getUrl.host + "/" + "api/";

//export var apiLink = "http://localhost:81/api/"

if (process.env.REACT_APP_DEBUG === "true") {
 apiLink = "http://127.0.0.1:81/api/"
}


const requestDashboard = () => {
  return { type: DASHBOARD_REQUEST, isFetching: true }
}

export const receiveDashboard = (response) => {
  dashboard = response
  //localStorage.setItem("language", response.display.language)
  //i18n.changeLanguage(localStorage.getItem("language"));
  return Object.assign({}, {type: DASHBOARD_SUCCESS}, response)
}

const dashboardError = (message) => {
  if (!!dashboard) {
    message = dashboard
  }
  return Object.assign({}, {type: DASHBOARD_SUCCESS}, message)
}

const receiveLanguage = (response) => {
  localStorage.setItem("language", response)
  i18n.changeLanguage(localStorage.getItem("language"));

  return Object.assign({}, {type: LANGUAGE_SUCCESS}, response)
}

export const fetchDashboard = (params) => {
  return dispatch => {
    dispatch(requestDashboard())
    return callApi(`/dashboard${params}`)
      .then(json => dispatch(receiveDashboard(json)))
      .catch(err => {
        dispatch(dashboardError(err.errors))
        // throw err.errors
      })
  }
}

export const fetchCityOfPlace = (params) => {
  return dispatch => {
    dispatch(requestDashboard())
    return callApi(`/getcity${params}`)
      .then(json => dispatch(receiveDashboard(json)))
      .catch(err => {
        dispatch(dashboardError(err.errors))
      })
  }
}

export const fetchLanguage = () => {
  return dispatch => {
    dispatch(requestDashboard())
    return callApi('/getlanguage')
    .then(json => dispatch(receiveLanguage(json)))
    .catch(err => {
      console.log(err)
    })
  }
}

export const getPromotionsByTag = (displayID, tagName) => {
  const currentdate = new Date(); 
  const datetime = currentdate.getFullYear() + "-" + ((currentdate.getMonth()+1) < 10 ? "0" + (currentdate.getMonth()+1) : (currentdate.getMonth()+1)) + "-" + (currentdate.getDate() < 10 ? "0" + currentdate.getDate() : currentdate.getDate());
  return fetch(apiLink + "getpromotionsbytag?display_id=" + displayID + "&tag=" + tagName + "&date=" + datetime, {
    method: "GET",
  })
  .then(res => {
    if (res.status === 200) return res.json()
    console.error("getPromotionsByTag returned an error:", res)
    return Promise.reject(new Error('getPromotionsByTag returned an error'))
  })
}

