const theme = {
  bg: "white",
  prefix: "signature",
  white: {
    background: "#001e60",
    backgroundLight: "#005aa2",
    border: "#005aa2",
    borderDark: "#4486bb",
    text: "#001e60",
    textLight: "#005aa2",
    header: { border: "#d0021b" },
    images: {
      glyph: "../../../images/signature/glyph-blue.png",
      signature: "../../../images/signature/signature-white.png",
      stamp: "../../../images/signature/stamp.png"
    },
    tags: {
      outer: "#215aa6",
      inner: "#dd2a25"
    }
  }
}

export default theme
