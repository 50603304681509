import {
  FLUIDITY_REQUEST, FLUIDITY_SUCCESS, FLUIDITY_FAILURE
} from '../actions/fluidity'

const fluidity = (state = { isFetched: false, isFetching: false, records: [] }, action) => {
  switch (action.type) {
    case FLUIDITY_REQUEST:
    return Object.assign({}, state, { isFetching: true })
    
    case FLUIDITY_SUCCESS:
      // @AYME changed how we access the records in the action payload
      return Object.assign({}, state, { isFetched: true, isFetching: false, records: action.records, errorMessage: '' })

    case FLUIDITY_FAILURE:
      return Object.assign({}, state, { isFetching: false, records: [], errorMessage: action.message })

    default:
      return state
  }
}

export default fluidity
