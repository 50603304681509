import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAILURE,
  LANGUAGE_SUCCESS
} from "../actions/dashboard"

export default function dashboard(
  state = {
    isFetched: false,
    isFetching: false,
    cartes: [],
    device: {},
    display: {},
    config: {},
    edito_items: []
  },
  action
) {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case DASHBOARD_SUCCESS:
      return Object.assign({}, state, {
        isFetched: true,
        isFetching: false,
        cartes: action.cartes,
        device: action.device,
        display: action.display,
        config: action.config,
        edito_items: action.edito_items,
        errorMessage: ""
      })

    case DASHBOARD_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        cartes: [],
        device: {},
        display: {},
        config: {},
        edito_items: [],
        errorMessage: action.message
      })

    case LANGUAGE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
      })


    default:
      return state
  }
}
