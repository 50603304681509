import React, { Suspense } from "react"
import { Provider } from "react-redux"
import { I18nextProvider } from "react-i18next"
import { displayStore } from "./store"
import i18n from "./middleware/i18n"
import "./styles/display.css"

import { DisplayContainer } from "./containers"

const store = displayStore()

function App() {
    return (
        <Suspense fallback="loading">
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <DisplayContainer />
                </Provider>
            </I18nextProvider>
        </Suspense>
    )
}

export default App;