import { callApi } from '../middleware/api'

export const FLUIDITY_REQUEST = 'FLUIDITY_REQUEST'
export const FLUIDITY_SUCCESS = 'FLUIDITY_SUCCESS'
export const FLUIDITY_FAILURE = 'FLUIDITY_FAILURE'

const requestFluidity = () => {
  return { type: FLUIDITY_REQUEST, isFetching: true }
}

export const receiveFluidity = (response) => {
  // @AYME changed the object stuct because this was creation a weird Object / Array hybrid with a {0:} key
  // Now the API sends typical array 
  return Object.assign({}, {type: FLUIDITY_SUCCESS, records: response })
}

const fluidityError = (message) => {
  return { type: FLUIDITY_FAILURE, message }
}

export const fetchFluidity = (params) => {
  return dispatch => {
    dispatch(requestFluidity())
    return callApi(`/fluiditydisplay${params}`)
      .then(json => {
        dispatch(receiveFluidity(json))
      })
      .catch(err => {
        dispatch(fluidityError(err.errors))
        throw err.errors
      })
  }
}
